header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--white);
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  z-index: 999;
}

header,
.header_content,
.logo_holder,
.header_content ul {
  display: flex;
  align-items: center;
}

.header_content {
  justify-content: space-between;
  color: var(--three);
}

.logo_holder {
  cursor: pointer;
  justify-content: center;
  font-weight: 800;
  gap: 10px;
  letter-spacing: 1px;
  font-size: 20px;
}

.image_holder img {
  position: relative;
  width: 148px;
}

.header_content ul a {
  color: var(--three);
  padding: 10px 20px;
}

.header_content ul a:hover {
  opacity: 0.7;
}

.header_content ul li button {
  background-color: var(--six);
  color: var(--three);
}

.header_content ul li button::before {
  background-color: var(--three);
}

.header_content ul li button:hover {
  color: var(--six);
}

.menu {
  text-align: center;
  width: 30px;
  height: 30px;
  background-color: var(--six);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: none;
  cursor: pointer;
}
.menu i {
  font-size: 18px;
  margin-top: 5px;
}

.menu_overlay {
  position: fixed;
  left: 0;
  top: 70px;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 900px) {
  header {
    height: 70px;
  }
  .menu {
    display: block;
  }

  .header_content ul {
    border-top: 1px solid #eee;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    position: fixed;
    right: -300px;
    top: 69px;
    width: 280px;
    height: calc(100vh - 70px);
    background-color: var(--white);
    transition: var(--main-transition);
    -webkit-transition: var(--main-transition);
    -moz-transition: var(--main-transition);
    -ms-transition: var(--main-transition);
    -o-transition: var(--main-transition);
    z-index: 999;
  }

  ul.activeList {
    right: 0;
  }

  .active_overlay {
    width: 100%;
  }
}

.cart_icon {
  padding: 8px;
}

.container_div {
  display:flex;
}
.fixed {
  width: 50px;
}
.flex_item {
  flex-grow: 1;
}